import React from "react"
import PropTypes from "prop-types"
import { getMonthYearFromStrings } from "../../utils/utils"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer";


var classNames = require("classnames")

function ImageCaption(props) {
    let nameClasses = classNames("image-caption staff fixed-facewall-name", {
        last: !props.procedure && !props.monthYear,
    })
    let procedureClasses = classNames(
        "image-caption staff fixed-facewall-procedure",
        {
            last: !props.monthYear,
        }
    )
    let timeClasses = classNames("image-caption staff fixed-facewall-time", {
        last: true,
    })
    return (
        <div className="image-caption-wrapper">
            {props.reviewerName && (
                <h6 className={nameClasses}>
                    {props.language === "es" ? "Conoce a" : "Meet"} {props.reviewerName}
                </h6>
            )}
            {props.language === "es"
                ? props.procedureEs &&
                !props.hideProcedureNames && (
                    <h6 className={procedureClasses}>{props.procedureEs}</h6>
                )
                : props.procedure &&
                !props.hideProcedureNames && (
                    <h6 className={procedureClasses}>
                        <MarkdownViewer markdown={props.procedure}/>
                    </h6>
                )}
            {props.monthYear && (
                <h6 className={timeClasses}>
                    {getMonthYearFromStrings(props.monthYear.year, props.monthYear.month)}
                </h6>
            )}
        </div>
    )
}

ImageCaption.propTypes = {
    reviewerName: PropTypes.string,
    language: PropTypes.string,
    procedure: PropTypes.string,
    procedureEs: PropTypes.string,
    hideProcedureNames: PropTypes.bool,
    monthYear: PropTypes.object
}

export default ImageCaption
