import PropTypes from "prop-types"
import React from "react"
import BulmaColumns from "./BulmaColumns"

export default function CenterColumn(props) {
    return (
        <BulmaColumns className={props.className} style={props.style}>
            {props.leftColWidth !== 0 && (
                <div className={`column is-${props.leftColWidth}`}></div>
            )}
            {props.centerColumn}
            {props.rightColWidth !== 0 && (
                <div className={`column is-${props.rightColWidth}`}></div>
            )}
        </BulmaColumns>
    )
}

CenterColumn.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    leftColWidth: PropTypes.number,
    rightColWidth: PropTypes.number,
    centerColumn: PropTypes.element
}
