import React from "react"
import PropTypes from "prop-types"
import CenterColumn from "./Bulma/CenterColumn"
import HorizontalTwoColumns from "./Bulma/HorizontalTwoColumns"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ImageMeta from "./ImageMeta"

import Button from '@bit/azheng.joshua-tree.button';
import ButtonGroupMap from './Button/ButtonGroupMap';

var classNames = require("classnames");

function ImageTexts({ imageTexts, imageTopMobile }) {

    if (!imageTexts[0].hasThisSection) return <></>

    // var bannerClasses = classNames(queryType, {
    //     "top-section text-section": true,

    // })

    var imageTextsClasses = classNames("image-text-section is-vcentered", {
        "image-top-mobile": imageTopMobile
    })

    return (
        imageTexts.map((imageText, i) => {
            return (
                <HorizontalTwoColumns
                    key={i}
                    className={imageTextsClasses}
                    leftColWidth={imageText.leftColWidth}
                    middleColWidth={imageText.middleColWidth}
                    rightColWidth={imageText.rightColWidth}
                    leftColumn={
                        imageText.textLeft ?
                            <>
                            {imageTopMobile && <Image
                                className="is-hidden-tablet"
                                width={imageText.leftContentColWidth}
                                imageId={imageText.imageId}
                            />}
                                <Text
                                    width={imageText.leftContentColWidth}
                                    text={imageText.text}
                                    buttons={imageText.buttons}
                                />
                            </>
                            :
                            <Image
                                className={imageTopMobile ? null : "is-hidden-mobile"}
                                width={imageText.leftContentColWidth}
                                imageId={imageText.imageId}
                            />
                    }
                    rightColumn={
                        imageText.textLeft ?
                            <Image
                                className={imageTopMobile ? "is-hidden-mobile" : null}
                                width={imageText.rightContentColWidth}
                                imageId={imageText.imageId}
                            />
                            :
                           <>
                                <Text
                                    width={imageText.rightContentColWidth}
                                    text={imageText.text}
                                    buttons={imageText.buttons}
                                />
                                {!imageTopMobile && <Image
                                className="is-hidden-tablet"
                                width={imageText.rightContentColWidth}
                                imageId={imageText.imageId}
                            />}
                           </>
                    }
                />
            )
        }
        )
    )
}

function Text({ width, text, buttons }) {
    return (
        <div 
        data-aos="fade-up"
        data-aos-duration="200"
        className={`column image-text-section--text ${width ? "is-" + width : ""}`}>
            <MarkdownViewer markdown={text} />
            {buttons && <ButtonGroupMap buttons={buttons} />}
        </div>        
    )
}

function Image({ width, imageId, className }) {
    return (
        <div 
        data-aos-duration="1200"
        data-aos="fade-left"
        data-aos-anchor-placement="center-bottom"
        className={`column ${className} ${width ? "is-" + width : ""}`}>
            <ImageMeta
                cloudName="nuvolum"
                publicId={imageId}
                responsive
                width="auto"
            />
        </div>        
    )
}

export default ImageTexts;