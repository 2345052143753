import React from "react"
import PropTypes from "prop-types"

import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import ImageMeta from "../../components/ImageMeta"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"

function InTheCommunity(props) {

  if (!props.data.hasThisSection) {
    return <div></div>
  }

  return (
    <>
      <HorizontalTwoColumns
        className="in-the-community color-back show-phone-only-inverse is-vcentered"
        leftColWidth={1}
        rightColWidth={1}
        middleColWidth={1}
        leftColumn={
          <div 
            className="column"
            data-aos="fade-right" 
            data-aos-duration="1200"
          >
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.data.imageId}
              data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/${props.data.imageId}`}
              responsive
            />
          </div>
        }
        rightColumn={
          <div 
            className="column community-text-block"
            data-aos="fade-left" 
            data-aos-duration="1200"
          >
            <h3>{props.data.subheading}</h3>
            <h2>{props.data.heading}</h2>
            <p className="large">{props.data.text}</p>
            <ButtonGroupMap buttons={props.data.buttons} />
          </div>
        }
      />
      <HorizontalTwoColumns
        className="in-the-community color-back show-phone-only-block"
        leftColWidth={2}
        rightColWidth={2}
        middleColWidth={1}
        leftColumn={
          <div className="column">
            <h3>{props.data.subheading}</h3>
            <h2>{props.data.heading}</h2>
            <p className="large">{props.data.text}</p>
            <ImageMeta
              cloudName="nuvolum"
              publicId={props.data.imageId}
              data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_auto/v1/${props.data.imageId}`}
              responsive
            />
            <ButtonGroupMap buttons={props.data.buttons} />
          </div>
        }
        rightColumn={
          <div></div>
        }
      />
    </>
  )
}

export default InTheCommunity